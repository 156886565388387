import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router.js";
import store from "./store/index.js";
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";

const options = {
  position: POSITION.TOP_CENTER,
  timeout: 3000,
  closeOnClick: true,
};

Vue.use(Toast, options);

const app = new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
});

app.$mount("#app");