import Vue from "vue";
import VueRouter from "vue-router";
import HomePage from "./components/Views/HomePage.vue";
import NotFound from "./components/Nav/NotFound.vue";
import AdminSys from "./components/Views/AdminSys.vue";
import AdminDashboard from "./components/Views/AdminDashboard.vue";
import Cart from "./components/Views/Cart.vue";

Vue.config.productionTip = false;
Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      component: HomePage,
      name: "Home",
    },
    {
      path: "/carrito",
      component: Cart,
      name: "Carrito",
    },
    {
      path: "/admin",
      component: AdminSys,
      name: "AdminSys"
    },
    {
      path: "/dashboard",
      component: AdminDashboard,
      name: "AdminDashboard"
    },
    {
      path: "/:notFound(.*)",
      component: NotFound,
    },
  ],
});

export default router;
