<template>
  <v-container class="d-flex flex-column justify-center align-center">
    <h2 class="pb-2">Iniciar Sesión</h2>
    <v-form @submit.prevent="entrar()" v-model="isFormValid">
      <v-col cols="12">
        <v-row>
          <v-text-field
            v-model="username"
            outlined
            label="Usuario"
            dense
            :rules="[rules.required]"
          ></v-text-field>
        </v-row>
        <v-row>
          <v-text-field
            v-model="password"
            outlined
            dense
            label="Contraseña"
            :rules="[rules.required]"
            :append-icon="showPassword ? eyeOpenIcon : eyeCloseIcon"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
          ></v-text-field>
        </v-row>
        <v-row class="mt-0 d-flex flex-column justify-center align-center">
          <p v-if="userPassIncorrect" class="red--text">{{ message }}</p>
        </v-row>
        <v-row class="d-flex flex-column justify-center align-center">
          <v-btn type="submit" :disabled="!isFormValid" color="primary">
            Entrar
          </v-btn>
        </v-row>
      </v-col>
    </v-form>
  </v-container>
</template>

<script>
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
import enums from "@/utils/enums";

export default {
  name: "AdminSys",
  data() {
    return {
      username: "",
      password: "",
      errorMessage: "", // Variable para almacenar mensajes de error
      isFormValid: false,
      rules: rules,
      showPassword: false,
      userPassIncorrect: false,
      message: "",
      eyeOpenIcon: enums.icons.EYE,
      eyeCloseIcon: enums.icons.EYE_OFF,
      closeIcon: enums.icons.CLOSE,
    };
  },
  mounted() {
    this.checkToken(); // Verificar el token cuando el componente se monte
  },
  methods: {
    ...mapActions({
      login: "admin/login",
      verifyToken: "admin/verifyToken" // Acción para verificar el token
    }),
    async entrar() {
      const data = {
        username: this.username,
        password: this.password,
      };

      const res = await this.login(data);
      if (res.success) {
        this.userPassIncorrect = false;
        this.message = "";
        // Guardar el token en el almacenamiento local
        localStorage.setItem("token", res.token);
        // Redirigir al dashboard de artículos
        this.$router.push("/dashboard");
      } else {
        this.userPassIncorrect = true;
        this.message = res.message;
      }
    },
    async checkToken() {
      const token = localStorage.getItem("token");

      if (token) {
        try {
          const response = await this.verifyToken();

          if (response.success) {
            this.$router.push("/dashboard");
          } else {
            localStorage.removeItem("token"); // Eliminar el token si la verificación falla
          }
        } catch (error) {
          console.error("Error al verificar el token:", error);
          localStorage.removeItem("token"); // Eliminar el token en caso de error
        }
      }
    }
  },
};
</script>
