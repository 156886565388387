const icons = Object.freeze({
  DOWN_ARROW_ICON: "mdi-chevron-down",
  UP_ARROW_ICON: "mdi-chevron-up",
  CLEAR_FILTERS: "mdi-filter-remove-outline",
  SEARCH: 'mdi-magnify',
  MAP_MARKER: 'mdi-map-marker',
  CLOSE: 'mdi-close',
  EDIT: 'mdi-pencil',
  DESHABILITAR: 'mdi-minus-circle-outline',
  EYE: 'mdi-eye',
  EYE_OFF: 'mdi-eye-off',
  EMAIL: 'mdi-email-outline',
  INSTAGRAM: 'mdi-instagram',
  HABILITAR: 'mdi-plus-circle-outline',
  LEFT_ARROW: 'mdi-chevron-left',
  RIGHT_ARROW: 'mdi-chevron-right',
  CARRITO: 'mdi-cart',
  SIGNO_MENOS: 'mdi-minus',
  SIGNO_MAS: 'mdi-plus',
  DELETE: 'mdi-delete',
  CHECK: 'mdi-check',
  INFO: 'mdi-information-outline',
  FILTER: 'mdi-tune-variant',
  ARROW_UP: 'mdi-arrow-up',
  ARROW_DOWN: 'mdi-arrow-down',
  EMPTY_CART: 'mdi-cart-outline',
});

export default icons;
