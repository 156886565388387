const actions = {
  // Acción para actualizar el carrito con un nuevo producto
  updateCart(context, productObj) {
    context.commit("setInCart", productObj);
  },
  // Acción para eliminar un producto específico del carrito
  removeCartItem(context, productId) {
    context.commit("unsetInCart", productId);
  },
  // Acción para vaciar completamente el carrito
  emptyCart(context) {
    context.commit("unsetCart");
  },
  updateCartQuantity({ commit }, { interno, cantidad }) {
    commit("updateItemQuantity", { interno, cantidad });
  },
};

export default actions;
