import axios from "axios";
import router from "@/router";

const actions = {
  async checkToken(context) {
    const token = localStorage.getItem("token");

    if (token) {
      try {
        const response = await context.dispatch("verifyToken");

        if (!response.success) {
          localStorage.removeItem("token"); // Eliminar el token si la verificación falla
          router.push({
            path: "/admin",
            query: { invalidToken: true },
          });
        }
      } catch (error) {
        console.error("Error al verificar el token:", error);
        localStorage.removeItem("token"); // Eliminar el token en caso de error
        router.push({
          path: "/admin",
          query: { invalidToken: true },
        });
      }
    } else {
      console.error("Sesión caducada.")
      this.$toast.error("Sesión caducada.", {
        timeout: false
      });
      router.push({
        path: "/admin",
      });
    }
  },
  async getProducts(context, payload) {
    const apiUrlBase = `${process.env.VUE_APP_BACKEND_URL}`;
    const apiUrl =
      `${apiUrlBase}/articulos?` +
      `${payload?.interno ? "&interno=" + payload.interno : ""}` +
      `${payload?.denominacion ? "&denominacion=" + payload.denominacion : ""}` +
      `${payload?.precioMin ? "&precioMin=" + payload.precioMin : ""}` +
      `${payload?.precioMax ? "&precioMax=" + payload.precioMax : ""}` +
      `${payload?.page ? "&page=" + payload.page : ""}` +
      `${payload?.limit ? "&limit=" + payload.limit : ""}` +
      `${payload?.habilitado ? "&habilitado=" + true : "&habilitado=" + false}` +
      `${payload?.equivalencias ? "&equivalencias=" + true : "&equivalencias=" + false}`;
    const response = await axios.get(apiUrl);
    return response.data;
  },
  async getImageUrl(context, payload) {
    const response = await axios.post(
      `https://api.cloudinary.com/v1_1/${process.env.VUE_APP_CLOUDINARY_CODE}/image/upload`,
      payload
    );
    return response.data;
  },
  async updateArticle(context, payload) {
    await context.dispatch("checkToken"); // Llamar a checkToken como acción de Vuex
    const response = await axios.put(
      `${process.env.VUE_APP_BACKEND_URL}/articulos`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${localStorage.getItem("token")}`, // Si necesitas enviar el token de autenticación
        },
      }
    );
    return response.data;
  },
  async deleteProduct(context, payload) {
    await context.dispatch("checkToken"); // Llamar a checkToken como acción de Vuex
    const response = await axios.request({
      method: "delete",
      url: `${process.env.VUE_APP_BACKEND_URL}/articulos`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("token")}`, // Asegúrate de que el token esté precedido por 'Bearer'
      },
    });
    return response.data;
  },
  async verifyToken() {
    const response = await axios.get(
      `${process.env.VUE_APP_BACKEND_URL}/auth/check-token`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  },
};

export default actions;
