<template>
  <v-dialog v-model="showDialog" :max-width="isMobile ? '90%' : '40%'">
    <v-card>
      <v-card-title>
        <v-row class="align-items-center">
          <v-col :cols="isMobile ? 8 : 'auto'">
            <span class="title-text text-button">Artículos equivalentes</span>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="text-right">
            <v-btn icon @click="showDialog = false">
              <v-icon>{{ closeIcon }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="pb-2 pr-2 pl-4 pt-0">
        <v-list dense>
          <v-list-item v-for="articulo in articulos" :key="articulo.interno">
            <EquivalentItem :articulo="articulo" />
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import EquivalentItem from './EquivalentItem.vue';
import enums from '@/utils/enums';
import { mapActions } from "vuex";

export default {
  components: {
    EquivalentItem,
  },
  props: {
    articulos: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      addedToCart: false,
      showDialog: false,
      closeIcon: enums.icons.CLOSE,
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    ...mapActions({
      updateCart: "cart/updateCart",
      removeCartItem: "cart/removeCartItem",
    }),
    open() {
      this.showDialog = true;
    },
    toggleAddToCart() {
      this.addedToCart = !this.addedToCart;

      // Crea el objeto del producto para agregarlo al carrito
      const product = {
        [this.interno]: {
          interno: this.interno,
          denominacion: this.denominacion,
          precio: this.precio,
          image: this.image,
          cantidad: 1,
        },
      };

      // Despacha la acción para agregar o quitar del carrito
      if (this.addedToCart) {
        this.updateCart(product);
      } else {
        this.removeCartItem(this.interno);
      }
    },
  }
};
</script>

<style scoped>
.title-text {
  white-space: nowrap;
}
</style>