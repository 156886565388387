<template>
  <v-container class="dashboard-container">
    <ExcelUploader />
    <FilterCard ref="FilterCardRef" :showExtraFilters="true" @loadProducts="loadProducts($event)" />
    <ArticlesTable ref="DataTableRef" @openFilters="openFilters()" />
  </v-container>
</template>

<script>
import ExcelUploader from "../UI/ExcelUploader.vue";
import FilterCard from "@/components/Layout/FilterCard.vue";
import ArticlesTable from "../UI/ArticlesTable.vue";
import enums from "@/utils/enums";
import { mapActions } from "vuex";

export default {
  name: "AdminDashboard",
  components: {
    ExcelUploader,
    FilterCard,
    ArticlesTable
  },
  data() {
    return {
      articles: [],
      closeIcon: enums.icons.CLOSE,
    };
  },
  mounted() {
    this.checkToken(); // Verificar el token cuando el componente se monte
    this.$refs.FilterCardRef.openFilters();
  },
  methods: {
    ...mapActions({
      verifyToken: "admin/verifyToken" // Acción para verificar el token
    }),
    loadProducts(payload) {
      this.$refs.DataTableRef.loadDataTable(payload);
    },
    openFilters() {
      this.$refs.FilterCardRef.openFilters();
    },
    async checkToken() {
      const token = localStorage.getItem("token");

      if (token) {
        try {
          const response = await this.verifyToken();

          if (!response.success) {
            localStorage.removeItem("token"); // Eliminar el token si la verificación falla
            this.$router.push("/admin");
          }
        } catch (error) {
          console.error("Error al verificar el token:", error);
          localStorage.removeItem("token"); // Eliminar el token en caso de error
          this.$router.push("/admin");
        }
      } else {
        console.error("Sesión caducada.")
        this.$toast.error("Sesión caducada.", {
          timeout: false
        });
        this.$router.push("/admin");
      }
    }
  },
};
</script>

<style scoped>
.dashboard-container {
  min-height: 600px !important;
}
</style>
