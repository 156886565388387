import * as XLSX from "xlsx";

export function processExcelFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    const articles = [];

    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]]; // Leer la primera hoja del Excel

        let row = 16; // Comienza desde la fila 16
        let columnInterno = "A";
        let columnDenominacion = "B";
        let columnDenominacionExtendida = "G";
        let columnStock = "K";
        let columnPrecio = "M";
        let hasMoreData = true;

        while (hasMoreData) {
          // Obtén los valores de las celdas que especificaste
          let interno = worksheet[`${columnInterno}${row}`]
            ? worksheet[`${columnInterno}${row}`].v
            : undefined;
          let denominacion = worksheet[`${columnDenominacion}${row}`]
            ? worksheet[`${columnDenominacion}${row}`].v
            : undefined;
          let denominacionExtendida = worksheet[
            `${columnDenominacionExtendida}${row}`
          ]
            ? worksheet[`${columnDenominacionExtendida}${row}`].v
            : undefined;
          let stock = worksheet[`${columnStock}${row}`]
            ? worksheet[`${columnStock}${row}`].v
            : undefined;
          let precio = worksheet[`${columnPrecio}${row}`]
            ? worksheet[`${columnPrecio}${row}`].v
            : undefined;

          // Verifica si las celdas están vacías para detener el bucle
          if (!interno && !denominacion && !denominacionExtendida && !stock) {
            hasMoreData = false; // Termina el bucle si las celdas están vacías
            break;
          }

          // Crea el objeto artículo
          const article = {
            interno: interno,
            denominacion: denominacion,
            denominacionExtendida: denominacionExtendida,
            stock: stock,
            precio: precio,
          };

          // Agrega el artículo a la lista
          articles.push(article);

          row++; // Incrementa el número de fila
        }

        // Resuelve la promesa con la lista de artículos
        resolve(articles);
      } catch (error) {
        reject(error); // Rechaza la promesa en caso de error
      }
    };

    reader.onerror = (error) => reject(error); // Rechaza en caso de error de lectura
    reader.readAsArrayBuffer(file);
  });
}
