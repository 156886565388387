<template>
  <div class="d-flex align-center flex-column pb-4">
    <div
      class="excel-uploader mb-4"
      :class="{ dragging }"
      @click="triggerFileInput"
      @dragover.prevent
      @dragenter="dragEnter"
      @dragleave="dragLeave"
      @drop.prevent="handleDrop"
    >
      <h2>Subir Archivo Excel</h2>
      <p v-if="dragging" class="drag-message">Suelta el archivo aquí</p>
      <p v-else class="normal-message">
        Arrastra y suelta el archivo Excel aquí o haz clic para seleccionar
      </p>

      <input
        type="file"
        ref="fileInput"
        @change="handleFileUpload"
        accept=".xlsx, .xls"
        style="display: none"
      />

      <!-- Mostrar nombre del archivo seleccionado -->
      <div v-if="fileName" class="file-info">
        <strong>Archivo seleccionado: {{ fileName }}</strong>
      </div>
    </div>

    <!-- Botón "Seleccionar archivo" -->
    <v-btn outlined color="primary" small @click="triggerFileInput" v-if="!file"
      >Seleccionar archivo</v-btn
    >

    <!-- Mostrar botón "Subir" solo si hay un archivo seleccionado -->
    <v-btn color="primary" small v-if="file" @click="uploadFile" :loading="isLoading">Subir</v-btn>
  </div>
</template>

<script>
import { processExcelFile } from "@/utils/helpers/excelManager";
import { mapActions } from "vuex";
import enums from "@/utils/enums";

export default {
  name: "ExcelUploader",
  data() {
    return {
      dragging: false, // Para manejar el estado de arrastre
      file: null, // Para almacenar el archivo seleccionado
      fileName: "", // Para mostrar el nombre del archivo
      closeIcon: enums.icons.CLOSE,
      isLoading: false,
    };
  },
  methods: {
    ...mapActions({
      uploadExcel: "admin/uploadExcel",
    }),
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.file = file; // Guardar el archivo seleccionado
        this.fileName = file.name; // Mostrar el nombre del archivo
      }
    },
    handleDrop(event) {
      const file = event.dataTransfer.files[0];
      if (file) {
        this.file = file; // Guardar el archivo
        this.fileName = file.name; // Mostrar el nombre del archivo
      }
      this.dragging = false;
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    dragEnter() {
      this.dragging = true;
    },
    dragLeave() {
      setTimeout(() => {
        if (!this.$refs.fileInput.files.length) {
          this.dragging = false;
        }
      }, 100);
    },
    async uploadFile() {
      this.isLoading = true;
      if (
        this.file &&
        (this.file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
          this.file.type === "application/vnd.ms-excel")
      ) {
        // Procesar el archivo Excel
        const articles = await processExcelFile(this.file);
        const data = {
            articulos: articles
        };
        const response = await this.uploadExcel(data);
        if (response.success) {
          this.$toast.success("Archivo procesado con éxito.");
        } else {
          this.$toast.error("Hubo un error al procesar el archivo.");
        }
      } else {
        this.$toast.warning("Por favor, selecciona un archivo de tipo Excel.");
      }
      this.file = null;
      this.fileName = "";
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.excel-uploader {
  border: 2px dashed #ccc;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: border-color 0.3s ease;
  position: relative;
  width: 80%;
  box-sizing: border-box;
}

.excel-uploader p {
  margin: 0;
}

.excel-uploader .file-info {
  margin-top: 10px;
  font-size: 14px;
  color: #1976d2;
}

.excel-uploader button {
  margin-top: 10px;
}

.excel-uploader.dragging {
  border-color: #00f;
}

.excel-uploader .drag-message {
  color: #00f;
}

.excel-uploader .normal-message {
  color: #333;
}
</style>
