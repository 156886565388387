<template>
  <v-card>
    <v-container
      class="pt-0 mb-4"
      :class="{ 'pb-0': !showFilters, 'pb-4': showFilters }"
    >
      <div class="text-overline ml-1 pt-2">Filtros</div>
      <v-card-actions>
        <v-row>
          <v-col cols="12" class="pa-0">
            <!-- chips con filtros seleccionados -->
            <v-container
              v-if="filtersApplied.length > 0 && !showFilters"
              class="pl-1 pt-1 pb-4"
            >
              <v-chip
                v-for="(filter, key) in filtersApplied"
                class="mr-2"
                label
                outlined
                color="primary"
                :key="key"
                @click="showFilters = true"
              >
                {{
                  `${filter.label}: ${
                    Array.isArray(filter.model)
                      ? getTextWhenFilterIsArray(filter)
                      : filter.model
                  }`
                }}
              </v-chip>
            </v-container>
          </v-col>
        </v-row>
      </v-card-actions>
      <v-expand-transition>
        <v-form
          v-show="showFilters"
          v-model="isFormValid"
          ref="filtersForm"
          id="filters-form"
          @submit.prevent="applyFilters"
        >
          <v-row>
            <v-col
              :cols="isMobile ? 12 : 3"
              class="pb-0 pt-0"
              v-if="showExtraFilters"
            >
              <v-text-field
                label="Nro. interno"
                v-model="nroInterno"
                clearable
                :rules="[rules.maxLength(nroInterno, 10)]"
                outlined
                dense
                :append-icon="searchIcon"
                @click:append="applyFilters()"
              />
            </v-col>
            <v-col
              :cols="isMobile ? 12 : showExtraFilters ? 3 : 6"
              class="pb-0 pt-0"
            >
              <v-text-field
                label="Denominación"
                v-model="inputDenominacion"
                clearable
                :rules="[rules.maxLength(inputDenominacion, 100)]"
                outlined
                dense
              />
            </v-col>
            <v-col :cols="isMobile ? 12 : 3" class="pb-0 pt-0">
              <v-text-field
                label="Precio mínimo"
                v-model="precioMin"
                clearable
                outlined
                dense
                :rules="[
                  rules.numericValue(precioMax),
                  rules.validPriceRange(precioMin, precioMax),
                ]"
              />
            </v-col>
            <v-col :cols="isMobile ? 12 : 3" class="pb-0 pt-0">
              <v-text-field
                label="Precio máximo"
                v-model="precioMax"
                clearable
                :rules="[
                  rules.numericValue(precioMax),
                  rules.validPriceRange(precioMin, precioMax),
                ]"
                outlined
                dense
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              :cols="isMobile ? 12 : 4"
              v-if="showExtraFilters"
              class="pt-0"
            >
              <v-switch
                v-model="enableSwitch"
                label="Artículos habilitados"
                hide-details
                class="labelSwitch"
                @change="openFilters()"
              ></v-switch>
            </v-col>
            <v-spacer></v-spacer>
            <v-col :cols="isMobile ? 12 : 4" class="pt-0 text-right">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="grey"
                    v-bind="attrs"
                    v-on="on"
                    class="mr-5 px-1 py-1"
                    size="25px"
                    @click="clearFilters"
                    id="v-icon-limpiarFiltros"
                    small
                  >
                    <v-icon>{{ clearFiltersIcon }}</v-icon>
                  </v-btn>
                </template>
                <span> Limpiar filtros</span>
              </v-tooltip>
              <v-btn
                color="primary"
                type="submit"
                form="filters-form"
                id="v-btn-aplicar"
                :disabled="
                  !inputDenominacion && !precioMin && !precioMax && !nroInterno
                "
                small
              >
                Aplicar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-expand-transition>
    </v-container>
  </v-card>
</template>

<script>
import enums from "@/utils/enums";
import rules from "@/utils/helpers/rules";

export default {
  name: "FilterCard",
  props: {
    showExtraFilters: { type: Boolean, default: false },
  },
  data() {
    return {
      filtersApplied: [],
      nroInterno: null,
      inputDenominacion: "",
      precioMin: null,
      precioMax: null,
      equivalencias: true,
      isFormValid: true,
      showFilters: true,
      clearFiltersIcon: enums.icons.CLEAR_FILTERS,
      searchIcon: enums.icons.SEARCH,
      rules: rules,
      enableSwitch: true,
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    getTextWhenFilterIsArray(currentFilter) {
      if (currentFilter.model.length > 0 && currentFilter.model.length <= 3) {
        return currentFilter.model
          .map((m) => (m.value ? m.value : m))
          .join(", ");
      }
      if (currentFilter.model.length > 3) {
        let totalFiltros = currentFilter.model.length - 3;
        let firstThree = currentFilter.model
          .slice(0, 3)
          .map((f) => (f.value ? f.value : f))
          .join(", ");
        return `${firstThree} y ${totalFiltros} más`;
      }
    },
    customizeFiltersApplied() {
      this.filtersApplied = [];
      if (this.nroInterno) {
        this.filtersApplied.push({
          key: "nroInterno",
          label: "Nro. interno",
          model: this.nroInterno,
        });
      }
      if (this.inputDenominacion) {
        this.filtersApplied.push({
          key: "denominacion",
          label: "Denominación",
          model: this.inputDenominacion,
        });
      }
      if (this.precioMin) {
        this.filtersApplied.push({
          key: "precioMin",
          label: "Precio mínimo",
          model: `$${this.precioMin}`,
        });
      }
      if (this.precioMax) {
        this.filtersApplied.push({
          key: "precioMax",
          label: "Precio máximo",
          model: `$${this.precioMax}`,
        });
      }
      if (this.enableSwitch && this.showExtraFilters) {
        this.filtersApplied.push({
          key: "enableSwitch",
          label: "Solo artículos habilitados",
          model: "SI",
        });
      }
      if (!this.enableSwitch && this.showExtraFilters) {
        this.filtersApplied.push({
          key: "enableSwitch",
          label: "Solo artículos habilitados",
          model: "NO",
        });
      }
    },
    clearFilters() {
      this.nroInterno =
        this.inputDenominacion =
        this.precioMin =
        this.precioMax =
          null;
      this.showFilters = this.enableSwitch = true;

      const data = {
        interno: "",
        denominacion: "",
        precioMin: "",
        precioMax: "",
        limit: this.showExtraFilters ? null : 9,
        page: 1,
        habilitado: this.enableSwitch,
        equivalencias: false,
      };

      this.$emit("loadProducts", data);
    },
    async applyFilters() {
      this.showFilters = false;
      this.customizeFiltersApplied();
      const data = {
        interno: this.nroInterno ? this.nroInterno.trim() : this.nroInterno,
        denominacion: this.inputDenominacion
          ? this.inputDenominacion.trim()
          : this.inputDenominacion,
        precioMin: this.precioMin ? this.precioMin.trim() : this.precioMin,
        precioMax: this.precioMax ? this.precioMax.trim() : this.precioMax,
        limit: this.showExtraFilters ? null : 9,
        page: 1,
        habilitado: this.enableSwitch,
        equivalencias: false,
      };
      this.$emit("loadProducts", data);
    },
    openFilters() {
      this.showFilters = true;
      const data = {
        interno: this.nroInterno,
        denominacion: this.inputDenominacion,
        precioMin: this.precioMin,
        precioMax: this.precioMax,
        limit: this.showExtraFilters ? null : 9,
        page: 1,
        habilitado: this.enableSwitch,
        equivalencias: false,
      };
      this.$emit("loadProducts", data);
    },
  },
};
</script>

<style scoped>
.text-overline {
  font-size: 15px !important;
}
.labelSwitch {
  margin-top: 0px !important;
}
</style>
