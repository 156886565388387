<template>
  <v-row class="cart-item">
    <v-col cols="auto">
      <v-img :src="localItem.image" max-width="80" max-height="80"></v-img>
    </v-col>

    <v-col class="cart-item-details" cols="auto">
      <div class="cart-item-title">{{ localItem.denominacion }}</div>
      <div class="cart-item-subtitle">
        Precio: ${{ formatPrice(localItem.precio) }}
      </div>
    </v-col>

    <v-col cols="auto">
      <v-row class="d-flex align-center">
        <v-btn icon @click="decrementQuantity">
          <v-icon>{{ minusIcon }}</v-icon>
        </v-btn>

        <v-text-field
          v-model="localItem.cantidad"
          type="number"
          class="quantity-field"
          min="1"
          hide-spin-buttons
          @input="updateSubtotal"
        ></v-text-field>

        <v-btn icon @click="incrementQuantity">
          <v-icon>{{ plusIcon }}</v-icon>
        </v-btn>
      </v-row>
    </v-col>

    <v-col cols="auto">
      <v-row>
        <div class="cart-item-subtitle">
          Subtotal: ${{ formatPrice(localItem.precio * localItem.cantidad) }}
        </div>
      </v-row>
    </v-col>

    <v-col cols="auto">
      <v-btn icon color="red" @click="removeItem">
        <v-icon>{{ deleteIcon }}</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import enums from "@/utils/enums";
import { mapActions } from "vuex";

export default {
  name: "CartItem",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localItem: { ...this.item },
      previousSubtotal: this.item.precio * this.item.cantidad, // Subtotal inicial
      minusIcon: enums.icons.SIGNO_MENOS,
      plusIcon: enums.icons.SIGNO_MAS,
      deleteIcon: enums.icons.DELETE,
    };
  },
  watch: {
    item: {
      handler(newValue) {
        this.localItem = { ...newValue };
        this.previousSubtotal = this.localItem.precio * this.localItem.cantidad; // Actualizar subtotal inicial
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      updateCartQuantity: "cart/updateCartQuantity",
    }),
    formatPrice(price) {
      return price
        .toFixed(2)
        .replace(".", ",")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    updateSubtotal() {
      const newSubtotal = this.localItem.precio * this.localItem.cantidad;
      const change = newSubtotal - this.previousSubtotal;
      this.$emit("update-subtotal", change); // Emitir el cambio en el subtotal
      this.previousSubtotal = newSubtotal; // Actualizar subtotal anterior

      // Actualizar cantidad en el estado global de Vuex
      this.updateCartQuantity({
        interno: this.localItem.interno,
        cantidad: this.localItem.cantidad,
      });
    },
    incrementQuantity() {
      this.localItem.cantidad++;
      this.updateSubtotal();
    },
    decrementQuantity() {
      if (this.localItem.cantidad > 1) {
        this.localItem.cantidad--;
        this.updateSubtotal();
      }
    },
    removeItem() {
      this.$emit("remove-item", this.localItem); // Emitir el ítem para ser removido
    },
  },
};
</script>

<style scoped>
.cart-item {
  border-bottom: 1px solid #e0e0e0;
  padding: 10px 0;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.cart-item img {
  border-radius: 4px;
}

.cart-item-details {
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cart-item-title {
  font-size: 18px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px; 
}

.cart-item-price {
  font-size: 14px;
}

.quantity-field {
  width: 40px;
  text-align: center;
  font-size: 14px;
}

.quantity-field >>> input {
  text-align: center;
}

.v-btn {
  min-width: 32px;
  min-height: 32px;
}

.v-btn .v-icon {
  font-size: 20px;
}

.v-btn:hover {
  background-color: #f5f5f5;
}

.cart-item-subtitle {
  font-size: 16px;
  font-weight: bold;
}

.cart-item-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.v-btn.red {
  background-color: #ff5252;
  color: #fff;
}

.v-btn.red:hover {
  background-color: #ff1744;
}
</style>
