import axios from "axios";
import router from "@/router";

const actions = {
  async checkToken({ dispatch }) {
    const token = localStorage.getItem("token");

    if (token) {
      try {
        const response = await dispatch("verifyToken");

        if (!response.success) {
          localStorage.removeItem("token"); // Eliminar el token si la verificación falla
          router.push({
            path: "/admin",
            query: { invalidToken: true },
          });
        }
      } catch (error) {
        console.error("Error al verificar el token:", error);
        localStorage.removeItem("token"); // Eliminar el token en caso de error
        router.push({
          path: "/admin",
          query: { invalidToken: true },
        });
      }
    } else {
      console.error("Sesión caducada.")
      this.$toast.error("Sesión caducada.", {
        timeout: false
      });
      router.push({
        path: "/admin",
      });
    }
  },

  async login(context, payload) {
    const response = await axios.post(
      `${process.env.VUE_APP_BACKEND_URL}/auth/login`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  },

  async uploadExcel({ dispatch }, payload) {
    await dispatch("checkToken");

    const response = await axios.post(
      `${process.env.VUE_APP_BACKEND_URL}/articulos/file`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  },

  async verifyToken() {
    const response = await axios.get(
      `${process.env.VUE_APP_BACKEND_URL}/auth/check-token`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  },
};

export default actions;
