<template>
  <v-container>
    <NavBarHome />

    <v-row class="pl-4 pr-4">
      <CartItem
        v-for="(item, index) in cartItems"
        :key="index"
        :item="item"
        @remove-item="deleteCartItem"
        @update-subtotal="updateTotal"
      />
    </v-row>

    <!-- Carrito vacío -->
    <v-card
      v-if="cartItems.length == 0"
      class="d-flex flex-column align-center justify-center mt-15"
      flat
    >
      <v-icon size="60px" color="black" class="pt-2 pb-0">{{
        emptyCartIcon
      }}</v-icon>
      <v-card-title class="text-button">Carrito vacío</v-card-title>
      <v-card-text class="text-center empty-cart-text"
        >Recorré nuestra página y encargá <br />
        todos los productos que necesites</v-card-text
      >
      <v-card-actions>
        <v-btn @click="volver" outlined color="primary">Ver productos</v-btn>
      </v-card-actions>
    </v-card>

    <!-- Total -->
    <v-row justify="space-between">
      <v-col :cols="isMobile ? 12 : 8" class="pb-0">
        <v-card v-if="cartItems.length" class="mt-4">
          <v-alert
            outlined
            type="warning"
            prominent
            border="left"
          >
            Total estimado. Puede haber variaciones en los precios al momento de
            hacer el pedido.
          </v-alert>
        </v-card>
      </v-col>
      <v-col :cols="isMobile ? 12 : 4" :class="isMobile ? 'pt-0' : 'pb-0'">
        <v-card v-if="cartItems.length" :class="isMobile ? 'total-card' : 'mt-4 total-card'">
          <v-card-text
            class="d-flex justify-space-between align-center text-button"
          >
            <span>Total:</span>
            <span
              ><strong>${{ formatPrice(total) }}</strong></span
            >
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col cols="12" md="4" class="d-flex justify-end pt-0">
        <v-btn
          @click="limpiarCarrito"
          v-if="cartItems.length"
          outlined
          color="primary"
          class="mr-2"
          >Vaciar Carrito</v-btn
        >
        <v-btn @click="encargar" v-if="cartItems.length" color="primary"
          >Encargar</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CartItem from "../UI/CartItem.vue";
import router from "@/router";
import { mapActions, mapGetters } from "vuex";
import enums from "@/utils/enums";
import NavBarHome from "../Layout/NavBarHome.vue";

export default {
  name: "CartView",
  components: {
    CartItem,
    NavBarHome,
  },
  data() {
    return {
      total: 0, // Añadir una propiedad total para manejar el total del carrito
      infoIcon: enums.icons.INFO,
      emptyCartIcon: enums.icons.EMPTY_CART,
    };
  },
  mounted() {
    // Inicializar los subtotales en caso de que no estén definidos
    this.cartItems.forEach((item) => {
      if (item.cantidad == null) {
        // Verifica si 'cantidad' no está definida
        const updatedItem = { ...item, cantidad: 1, subtotal: item.precio };
        this.updateCart(updatedItem); // Actualiza el carrito con el nuevo item
      }
    });

    // Inicializa el total con los subtotales de todos los items
    this.total = this.cartItems.reduce(
      (sum, item) => sum + item.precio * item.cantidad,
      0
    );
  },
  computed: {
    ...mapGetters("cart", ["cartItems"]),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    ...mapActions({
      getProducts: "products/getProducts",
      deleteProduct: "products/deleteProduct",
      updateCart: "cart/updateCart",
      removeCartItem: "cart/removeCartItem",
      emptyCart: "cart/emptyCart",
    }),
    formatPrice(price) {
      return price
        .toFixed(2)
        .replace(".", ",")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    updateTotal(change) {
      this.total += change; // Sumar el cambio recibido del CartItem
    },
    deleteCartItem(item) {
      this.removeCartItem(item.interno); // Elimina el ítem del carrito
      this.updateTotal(-item.cantidad * item.precio); // Restar el subtotal del total
    },
    volver() {
      router.push("/"); // Regresa a la página de inicio
    },
    async encargar() {
      // Crea un array que contenga solo los 'interno' de cada producto
      const internos = this.cartItems.map((item) => item.interno);

      // Realiza una llamada API para cada 'interno'
      const requests = internos.map(async (interno) => {
        // Suponiendo que getProducts acepta un objeto con el número de interno
        const payload = { interno };
        const product = await this.getProducts(payload);
        return product;
      });

      // Usa Promise.all para esperar a que todas las llamadas se resuelvan
      const products = await Promise.all(requests);

      // Genera el mensaje con la información de los productos
      const mensaje = products
        .map((p, index) => {
          const articulo = p.articulos[0];
          const cantidad = this.cartItems[index].cantidad; // Obtén la cantidad del carrito
          return `· Producto: ${articulo.denominacion} - Cantidad: ${cantidad}`;
        })
        .join("\n\n");

      const mensajeCompleto = `Hola, quiero encargar lo siguiente:\n\n${mensaje}\n\nMuchas gracias.`;

      // Número de teléfono de destino en formato internacional (ejemplo: 5491234567890 para Argentina)
      const telefono = "5493516061910";

      // Codifica el mensaje para que sea válido en el enlace de WhatsApp
      const mensajeCodificado = encodeURIComponent(mensajeCompleto);

      // Crea el enlace de WhatsApp
      const enlaceWhatsApp = `https://wa.me/${telefono}?text=${mensajeCodificado}`;

      // Redirige al usuario a WhatsApp Web o la app de WhatsApp
      window.open(enlaceWhatsApp, "_blank");
    },
    limpiarCarrito() {
      this.emptyCart();
    },
  },
};
</script>

<style scoped>
.text-button {
  font-size: 20px !important;
  color: black !important;
  height: 100%; 
  display: flex;
  align-items: center; 
  justify-content: space-between; 
}
.empty-cart-text {
  font-size: 18px;
}
.total-card {
  height: 80px;
}
</style>
