import Vue from "vue";

const mutations = {
  // Mutación para agregar o actualizar un producto en el carrito
  setInCart(state, productObj) {
    const product = productObj[Object.keys(productObj)[0]];

    // Verificar si el producto ya está en el carrito
    const index = state.cart.findIndex(item => item.interno === product.interno);

    if (index !== -1) {
      // Actualizar producto existente
      Vue.set(state.cart, index, { ...state.cart[index], ...product });
    } else {
      // Agregar nuevo producto
      state.cart.push(product);
    }

    // Guardar el carrito en sessionStorage
    sessionStorage.setItem('cart', JSON.stringify(state.cart));
  },
  // Mutación para eliminar un producto específico del carrito
  unsetInCart(state, productId) {
    state.cart = state.cart.filter(item => item.interno !== productId);

    // Actualizar sessionStorage
    sessionStorage.setItem('cart', JSON.stringify(state.cart));
  },
  // Mutación para vaciar el carrito
  unsetCart(state) {
    state.cart = [];

    // Limpiar sessionStorage
    sessionStorage.removeItem('cart');
  },
  // Mutación para actualizar la cantidad de un producto
  updateItemQuantity(state, { interno, cantidad }) {
    const index = state.cart.findIndex(item => item.interno === interno);
    if (index !== -1) {
      Vue.set(state.cart, index, { ...state.cart[index], cantidad });
      
      // Guardar en sessionStorage
      sessionStorage.setItem('cart', JSON.stringify(state.cart));
    }
  },
};

export default mutations;
