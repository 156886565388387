import Vue from "vue";
import Vuex from "vuex";

import products from "./modules/products";
import admin from "./modules/admin";
import cart from "./modules/cart";

// Asegúrate de que Vuex esté siendo usado correctamente
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    products,
    admin,
    cart,
  },
});
