<template>
  <v-container class="pa-0 ma-0">
    <v-card>
      <v-card-title>
        <v-row class="align-items-center">
          <v-col :cols="isMobile ? 8 : 'auto'">
            <span class="title-text text-button">Editar artículo</span>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="text-right">
            <v-btn icon @click="closeModal()">
              <v-icon>{{ closeIcon }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="editArticleForm"
          @submit.prevent="saveArticle()"
        >
          <v-row>
            <v-col :cols="isMobile ? 12 : 6" class="pb-2 pt-0">
              <v-text-field
                label="Nro. interno"
                v-model="nroInterno"
                clearable
                outlined
                dense
                hide-details
                disabled
                readonly
                filled
              />
            </v-col>
            <v-col :cols="isMobile ? 12 : 6" class="pb-2 pt-0">
              <v-text-field
                label="Denominación"
                v-model="inputDenominacion"
                clearable
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col cols="12" class="pb-2 pt-0">
              <v-text-field
                label="Denominación extendida"
                v-model="denomExt"
                clearable
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col :cols="isMobile ? 12 : 6" class="pb-2 pt-0">
              <v-text-field
                label="Stock"
                v-model="stock"
                clearable
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col :cols="isMobile ? 12 : 6" class="pb-2 pt-0">
              <v-text-field
                label="Precio"
                v-model="precio"
                clearable
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col cols="12" class="pb-0 pt-0">
              <div class="image-container">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  v-if="isLoading"
                ></v-progress-circular>
                <v-img v-else class="image" :src="imagen" alt="Product Image">
                </v-img>
              </div>
            </v-col>
          </v-row>
          <v-row class="text-right pb-0">
            <v-col :cols="isMobile ? 12 : 6">
              <v-file-input
                outlined
                dense
                accept="image/*"
                label="Cambiar imágen"
                hide-details
                @change="setNewImagen($event)"
              ></v-file-input>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="text-rigth">
              <v-btn color="primary" type="submit" :disabled="!isFormValid">
                Guardar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import enums from "@/utils/enums";
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";

export default {
  name: "EditArticle",
  props: {
    articleItem: {
      type: Object,
    },
  },
  data() {
    return {
      isFormValid: false,
      closeIcon: enums.icons.CLOSE,
      rules: rules,
      nroInterno: null,
      inputDenominacion: null,
      denomExt: null,
      stock: null,
      precio: null,
      imagen: null,
      isLoading: false,
    };
  },
  created() {
    this.setData();
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    ...mapActions({
      getImageUrl: "products/getImageUrl",
      updateArticle: "products/updateArticle",
    }),
    setData() {
      this.inputDenominacion = this.articleItem.denominacion;
      this.denomExt = this.articleItem.denominacionExtendida;
      this.nroInterno = this.articleItem.interno;
      this.stock = this.articleItem.stock;
      this.precio = this.articleItem.precio;
      this.imagen = this.articleItem.imagen;
    },
    async setNewImagen(newImagen) {
      try {
        this.isLoading = true;

        if (!newImagen) {
          this.imagen = this.articleItem.imagen;
          this.isLoading = false;
          return;
        }

        const file = newImagen;
        const formData = new FormData();
        formData.append("file", file);
        formData.append("upload_preset", "axji3fv4");

        const response = await this.getImageUrl(formData);
        this.imagen = response.secure_url;
        this.isLoading = false;
      } catch (error) {
        console.error("Error al cargar la imagen:", error);
      }
    },
    async saveArticle() {
      const data = {
        interno: this.nroInterno,
        denominacion: this.inputDenominacion,
        denominacionExtendida: this.denomExt,
        stock: this.stock,
        precio: this.precio,
        imagen: this.imagen,
      };
      try {
        const response = await this.updateArticle(data);
        if (response.success) {
          this.$emit("openSuccessSnackbar");
        } else {
          this.$emit("openErrorSnackbar");
        }
      } catch (error) {
        console.error(error);
      }
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.image-container {
  height: 250px;
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.image {
  width: auto;
  max-height: 100%;
  object-fit: contain;
}
/* Media query para dispositivos móviles */
@media (max-width: 800px) {
  .image-container {
    width: 100%;
  }
}
.title-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
