<template>
  <div :class="!showMoreFilters ? 'normalNavBarClass' : 'showMoreFiltersClass'">
    <v-app-bar absolute elevate-on-scroll dark color="primary">
      <v-row class="align-center justify-space-between">
        <v-col cols="2">
          <v-btn @click="logoAction" icon>
            <img
              src="../../assets/logo-gulf.png"
              alt="Action Icon"
              class="action-icon"
            />
          </v-btn>
        </v-col>
        <v-col cols="6" class="mt-6" v-if="isHomePage">
          <v-row>
            <v-col class="pr-0">
              <v-text-field
                v-model="inputDenominacion"
                solo
                dense
                label="Buscar"
                light
                :append-icon="searchIcon"
                @click:append="searchProducts()"
                @keydown.enter="searchProducts()"
                class="search-box"
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="pa-0 mt-2">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    @click="showMoreFilters = !showMoreFilters"
                    icon
                  >
                    <v-icon>{{ moreFiltersIcon }}</v-icon>
                  </v-btn>
                </template>
                <span>Más filtros</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="2" class="text-right">
          <v-btn @click="toggleCart" icon>
            <v-icon>{{ carritoIcon }}</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-expand-transition class="pb-0">
      <div v-show="showMoreFilters" class="expand-content">
        <v-row>
          <v-col :cols="isMobile ? 12 : 3" class="pb-2">
            <v-text-field
              label="Precio mínimo"
              v-model="precioMin"
              clearable
              outlined
              dense
              :hide-details="!hasErrorsMin"
              :rules="[
                rules.numericValue(precioMin),
                rules.validPriceRange(precioMin, precioMax),
              ]"
              @keydown.enter="searchProducts()"
            />
          </v-col>
          <v-col :cols="isMobile ? 12 : 3" class="pb-2">
            <v-text-field
              label="Precio máximo"
              v-model="precioMax"
              clearable
              :rules="[
                rules.numericValue(precioMax),
                rules.validPriceRange(precioMin, precioMax),
              ]"
              outlined
              dense
              :hide-details="!hasErrorsMax"
              @keydown.enter="searchProducts()"
            />
          </v-col>
          <v-spacer></v-spacer>
          <v-col :cols="isMobile ? 12 : 3" class="pb-2 text-right">
            <v-btn
              color="primary"
              outlined
              @click="changeSort()"
              :icon="isMobile"
              class="mr-2"
            >
              <span v-if="!isMobile" class="mr-2">Ordenar</span>
              <v-icon>
                {{ ordAsc ? arrowUpIcon : arrowDownIcon }}
              </v-icon>
            </v-btn>

            <v-btn color="primary" @click="searchProducts()"> Aplicar </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import router from "@/router";
import enums from "@/utils/enums";
import rules from "@/utils/helpers/rules";

export default {
  name: "NavBarHome",
  data() {
    return {
      items: [
        {
          name: "Presentación",
          link: "/presentation",
        },
        {
          name: "Experiencia y educación",
          link: "/experience-education",
        },
        {
          name: "Aptitudes",
          link: "/skills",
        },
        {
          name: "Contacto",
          link: "/contact-me",
        },
      ],
      carritoIcon: enums.icons.CARRITO,
      searchIcon: enums.icons.SEARCH,
      moreFiltersIcon: enums.icons.FILTER,
      showMoreFilters: false,
      homePage: null,
      inputDenominacion: "",
      precioMin: null,
      precioMax: null,
      rules: rules,
      arrowUpIcon: enums.icons.ARROW_UP,
      arrowDownIcon: enums.icons.ARROW_DOWN,
      ordAsc: true,
    };
  },
  mounted() {
    const data = {
      denominacion: "",
      precioMin: null,
      precioMax: null,
      equivalencias: true,
      limit: 9,
      page: 1,
      habilitado: true,
      sortField: "precio",
      sortOrder: this.ordAsc ? "asc" : "desc",
    };
    this.$emit("loadProducts", data);
  },
  computed: {
    isHomePage() {
      return this.$route.path === "/";
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    hasErrorsMin() {
      const numericRule = this.rules.numericValue(this.precioMin);
      const rangeRule = this.rules.validPriceRange(
        this.precioMin,
        this.precioMax
      );
      return numericRule !== true || rangeRule !== true;
    },
    hasErrorsMax() {
      const numericRule = this.rules.numericValue(this.precioMax);
      const rangeRule = this.rules.validPriceRange(
        this.precioMin,
        this.precioMax
      );
      return numericRule !== true || rangeRule !== true;
    },
  },
  methods: {
    toggleCart() {
      const currentRoute = this.$route.path;
      if (currentRoute === "/carrito") {
        router.push("/");
      } else if (currentRoute === "/") {
        router.push("/carrito");
      }
    },
    logoAction() {
      const currentRoute = this.$route.path;
      if (currentRoute !== "/") {
        router.push("/");
      } else {
        const data = {
          denominacion: "",
          precioMin: null,
          precioMax: null,
          equivalencias: true,
          limit: 9,
          page: 1,
          habilitado: true,
          sortField: "precio",
          sortOrder: this.ordAsc ? "asc" : "desc",
        };
        this.$emit("loadProducts", data);
      }
    },
    searchProducts() {
      const data = {
        denominacion: this.inputDenominacion,
        precioMin: this.precioMin,
        precioMax: this.precioMax,
        equivalencias: true,
        limit: 9,
        page: 1,
        habilitado: true,
        sortField: "precio",
        sortOrder: this.ordAsc ? "asc" : "desc",
      };
      this.$emit("loadProducts", data);
    },
    changeSort() {
      this.ordAsc = !this.ordAsc;
      this.searchProducts();
    },
  },
};
</script>

<style scoped>
.text-button {
  font-size: 20px !important;
}
.action-icon {
  width: 50px;
  height: 50px;
}
.expand-content {
  padding: 16px;
  border: 1px solid #ddd;
  margin-top: 50px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.normalNavBarClass {
  margin-bottom: 64px;
}
.showMoreFiltersClass {
  margin-bottom: 0px;
  padding-bottom: 10px;
}
.search-box {
  border-radius: 20px;
}
</style>
