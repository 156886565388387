<template>
  <v-row class="align-center justify-space-between">
    <!-- Columna para la imagen -->
    <v-col cols="2">
      <v-list-item-avatar>
        <v-img :src="articulo.imagen" max-width="60"></v-img>
      </v-list-item-avatar>
    </v-col>

    <!-- Columna para el nombre y el precio -->
    <v-col cols="8">
      <v-list-item-content>
        <v-list-item-title class="product-title">{{ articulo.denominacion }}</v-list-item-title>
        <v-list-item-subtitle class="product-subtitle">Precio: ${{ formatPrice(articulo.precio) }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-col>

    <!-- Columna para el botón de agregar al carrito -->
    <v-col cols="2" class="text-right">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn :color="addedToCart ? 'green' : 'primary'" v-bind="attrs" v-on="on" @click="toggleAddToCart" icon>
            <v-icon v-if="!addedToCart">{{ carritoIcon }}</v-icon>
            <v-icon v-else>{{ checkIcon }}</v-icon>
          </v-btn>
        </template>
        <span v-if="!addedToCart">Agregar al carrito</span>
        <span v-else>Producto agregado</span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
import enums from '@/utils/enums';
import { mapActions, mapGetters } from "vuex";

export default {
  name: "EquivalentItem",
  props: {
    articulo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      addedToCart: false,
      carritoIcon: enums.icons.CARRITO,
      checkIcon: enums.icons.CHECK
    };
  },
  mounted() {
    // Verificar si el producto ya está en el carrito al cargar el componente
    this.addedToCart = this.isProductInCart;
  },
  computed: {
    ...mapGetters("cart", ["cartItems"]),
    isProductInCart() {
      return !!this.cartItems[this.articulo?.interno];
    }
  },
  methods: {
    ...mapActions({
      updateCart: "cart/updateCart",
      removeCartItem: "cart/removeCartItem",
    }),
    toggleAddToCart() {
      this.addedToCart = !this.addedToCart;

      // Crea el objeto del producto para agregarlo al carrito
      const product = {
        [this.articulo.interno]: {
          interno: this.articulo.interno,
          denominacion: this.articulo.denominacion,
          precio: this.articulo.precio,
          image: this.articulo.imagen,
          cantidad: 1,
        },
      };

      // Despacha la acción para agregar o quitar del carrito
      if (this.addedToCart) {
        this.updateCart(product);
      } else {
        this.removeCartItem(this.articulo.interno);
      }
    },
    formatPrice(price) {
      if (price == null) {
        price = 0; // Valor predeterminado
      }
      const formattedPrice = price
        .toFixed(2)
        .replace(".", ",")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return formattedPrice;
    },
  },
};
</script>

<style scoped>
.product-title {
  font-size: 16px !important;
}

.product-subtitle {
  font-size: 15px !important;
}
</style>