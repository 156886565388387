<template>
  <v-container>
    <div class="d-flex justify-end">
      <v-btn
        color="primary"
        @click="desableAllSelected"
        small
        :disabled="selectedItems.length === 0"
        >{{ enableDesableText }}</v-btn
      >
    </div>
    <v-data-table
      :headers="headers"
      :items="articulos"
      item-key="interno"
      class="elevation-1"
      dense
      :loading="isLoading"
      :items-per-page="100"
      :footer-props="{
        itemsPerPageOptions: [100, 200, 500, -1],
        itemsPerPageText: 'Artículos por página',
      }"
      no-data-text="No hay datos disponibles"
      loading-text="Cargando artículos..."
      show-select
      v-model="selectedItems"
      return-object
    >
      <template v-slot:[`item.precio`]="{ item }">
        <td>${{ item.precio }}</td>
      </template>
      <template v-slot:[`item.updatedAt`]="{ item }">
        <td>{{ formatDate(item.updatedAt) }}</td>
      </template>
      <template v-slot:[`item.imagen`]="{ item }">
        <v-img :src="item.imagen" max-width="100px"></v-img>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              @click="
                item.habilitado
                  ? openDeleteDialog(item)
                  : managerEnable(true, item)
              "
              icon
            >
              <v-icon size="20px">
                {{ item.habilitado ? deleteIcon : enableIcon }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{
            item.habilitado ? "Deshabilitar artículo" : "Habilitar artículo"
          }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" @click="editArticle(item)" icon>
              <v-icon size="20px">{{ editIcon }}</v-icon>
            </v-btn>
          </template>
          <span>Editar artículo</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog v-model="isOpenEdit" max-width="80%">
      <EditArticle
        :key="articleItem?.interno"
        :articleItem="articleItem"
        @close="closeModal()"
        @openSuccessSnackbar="openSuccessSnackbar"
        @openErrorSnackbar="openErrorSnackbar"
      />
    </v-dialog>

    <v-dialog v-model="isOpenDelete" max-width="500px">
      <v-card>
        <v-card-title>Deshabilitar</v-card-title>
        <v-card-text>
          Está seguro que desea deshabilitar el artículo
          <strong>{{ articleToDelete?.denominacion }}</strong
          >?
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn text small @click="closeDeleteDialog"> Cancelar </v-btn>
          <v-btn
            small
            color="primary"
            @click="managerEnable(false, articleToDelete)"
            :loading="isLoadingDelete"
          >
            Deshabilitar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import enums from "@/utils/enums";
import { mapActions } from "vuex";
import EditArticle from "./EditArticle.vue";

export default {
  name: "ArticlesTable",
  components: {
    EditArticle,
  },
  data() {
    return {
      headers: [
        { text: "Interno", value: "interno" },
        { text: "Denominación", value: "denominacion" },
        {
          text: "Denominación Extendida",
          value: "denominacionExtendida",
          sortable: false,
          width: "20%",
        },
        { text: "Stock", value: "stock", align: "center", sortable: false },
        { text: "Precio", value: "precio" },
        {
          text: "Última Actualización",
          value: "updatedAt",
        },
        { text: "Imagen", value: "imagen", align: "center", sortable: false },
        {
          text: "Acciones",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      editIcon: enums.icons.EDIT,
      deleteIcon: enums.icons.DESHABILITAR,
      articulos: [],
      isLoading: false,
      isOpenEdit: false,
      articleItem: null,
      isOpenDelete: false,
      articleToDelete: null,
      filters: null,
      enableIcon: enums.icons.HABILITAR,
      isLoadingDelete: false,
      selectedItems: [],
      enableDesableText: "",
    };
  },
  created() {
    this.loadDataTable();
  },
  methods: {
    ...mapActions({
      getProducts: "products/getProducts",
      deleteProduct: "products/deleteProduct",
    }),
    async loadDataTable(payload) {
      this.enableDesableText = payload?.habilitado
        ? "Deshabilitar seleccionados"
        : "Habilitar seleccionados";
      if (!payload) return;
      this.isLoading = true;
      this.filters = payload;
      const res = await this.getProducts(payload);
      this.articulos = res.articulos;
      this.isLoading = false;
    },
    formatDate(dateString) {
      const fecha = new Date(dateString);
      return `${fecha.getDay()}/${fecha.getMonth()}/${fecha.getFullYear()}`;
    },
    editArticle(articulo) {
      this.articleItem = articulo;
      this.isOpenEdit = true;
    },
    async managerEnable(enableFlag, itemToEnable) {
      const data = {
        interno: itemToEnable.interno,
        habilitado: enableFlag,
      };

      try {
        this.isLoadingDelete = true;
        const response = await this.deleteProduct(data);
        if (response.success) {
          this.openSuccessSnackbar();
        } else {
          this.openErrorSnackbar();
        }
      } catch (error) {
        console.error(error);
      }
      this.isLoadingDelete = false;
      this.isOpenDelete = false;

      this.loadDataTable(this.filters);
    },
    closeModal() {
      this.isOpenEdit = false;
    },
    openSuccessSnackbar() {
      this.closeModal();
      this.$emit("openFilters");
      this.$toast.success("Guardado con éxito.");
    },
    openErrorSnackbar() {
      this.closeModal();
      this.$emit("openFilters");
      this.$toast.error("Hubo un error al procesar el artículo.");
    },
    closeDeleteDialog() {
      this.isOpenDelete = false;
    },
    openDeleteDialog(articulo) {
      this.isOpenDelete = true;
      this.articleToDelete = articulo;
    },
    desableAllSelected() {
      this.selectedItems.forEach((item) => {
        this.managerEnable(!item.habilitado, item);
      });
      this.selectedItems = [];
    },
  },
};
</script>

<style scoped>
.v-data-table {
  margin-top: 20px;
}
</style>
