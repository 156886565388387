<template>
  <v-app>
    <NavBar v-if="!isHomePage"/>
    <v-main id="home">
      <div class="main-container">
        <router-view></router-view>
      </div>
    </v-main>
    <AppFooter />
  </v-app>
</template>

<script>
import AppFooter from "./components/Layout/AppFooter.vue";
import NavBar from "./components/Layout/NavBar.vue";

export default {
  name: "App",

  components: {
    AppFooter,
    NavBar,
  },

  data: () => ({
    //
  }),
  computed: {
    isHomePage() {
      return this.$route.path === "/" || this.$route.path === "/carrito";
    },
  },
};
</script>

<style>
.main-container {
  min-height: 500px;
  width: 100%;
}
</style>
